import React, { useEffect, useCallback, useState, useRef } from 'react';
import Layout from '../../components/structures/Layout/layout.js';
import Section from '../../components/structures/Section/section';
import Wrapper from '../../components/structures/Wrapper/wrapper';
import contentFilter from '../../services/contentFilter/contentFilter';
import { CardGroup } from '../../components/modules/CardGroup/cardGroup';
import { Card } from '../../components/modules/Card/card';
import { Button } from '../../components/elements/Button/button';
import { Title } from '../../components/elements/Title/title';
import { Text } from '../../components/elements/Text/text';
import sticky from '../../services/sticky/sticky';
import Column from '../../components/structures/Column/column';
import Radio from '../../components/modules/Form/Radio/radio';
import Document from '../../images/shared/document.png';
import Dropdown from '../../components/modules/Dropdown/dropdown';
import replaceVariable from '../../services/replaceVariable/replaceVariable';
import { ReminderForm } from '../../components/modules/ReminderForm/reminderForm';
import getParameterUrl from '../../services/getParameterUrl/getParameterUrl';
import setCookie from '../../services/cookie/setCookie';
import { Image } from '../../components/elements/Image/image';
import Stepbar from '../../components/modules/Stepbar/stepbar.js';
import { Benefits } from '../../components/modules/Benefits/benefits';
import { ModalWrapper } from '../../components/modules/Modal/ModalWrapper.js';
import { useModal } from '../../hooks/useModal.js';
import GroupButtons from '../../components/modules/GroupButtons/groupButtons.js';
import { useSite } from '../../context/siteContext';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Eligibility = (props) => {
  const location = props.path;
  const sections = props.pageContext.sections;
  const dataSite = props.pageContext.dataSite;
  const sectionEligibilityTest = contentFilter(
    sections,
    `eligibility-test-${props.pageContext.shortName}`,
  );
  const sectionBenefits = contentFilter(sections, 'benefits');
  const jsonObject = props.pageContext.jsonObject;
  const urlHash = props.pageContext.urlHash;
  const local = process.env.CONTENTFUL_LOCAL;

  const [isOpened, setIsOpened, modalLink, setModalLink] = useModal();

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  useEffect(() => {
    sticky();
    let utm_source = getParameterUrl('utm_source');
    let utm_medium = getParameterUrl('utm_medium');
    let cprice = getParameterUrl('cprice');
    let utm_content = getParameterUrl('utm_content');
    utm_source !== '' && setCookie('utm_source', utm_source, 30);
    utm_medium !== '' && setCookie('utm_medium', utm_medium, 30);
    cprice !== '' && setCookie('cprice', cprice, 30);
    utm_content !== '' && setCookie('utm_content', utm_content, 30);
  }, []);

  useEffect(() => {
    setModalLink('/' + props.pageContext.slug);
  }, []);

  const [stepActive, setStepActive] = useState('quiz1');
  const [error, setError] = useState(false);
  const [theArray, setTheArray] = useState(['quiz1']);

  const handleClickNext = useCallback(
    (name) => {
      let checked = false;
      let radios = document.querySelectorAll(`input[name=${name}]`);
      radios.forEach((radio) => {
        if (radio.checked) {
          checked = true;
        }
      });

      if (checked) {
        setError(false);
        const nextStep = document.querySelector(`input[name=${name}]:checked`).value;
        setStepActive(nextStep);
        setTheArray([...theArray, nextStep]);

        executeScroll();
      } else {
        setError(true);
      }
    },
    [theArray],
  );

  const handleClickBack = () => {
    setStepActive(theArray[theArray.length - 2]);
    let item = theArray[theArray.length - 1];
    setTheArray(theArray.filter((e) => e !== item));
    setError(false);

    executeScroll();
  };

  const goToFirstStep = () => {
    const allInputs = document.querySelectorAll('input');
    allInputs.forEach((input) => {
      input.checked = false;
    });
    setStepActive('quiz1');
    setTheArray(['quiz1']);
  };

  const templateStyle = props.pageContext.templateStyle;

  const isStepbar = templateStyle.body.stepbar;
  const title =
    templateStyle.eligibility.title === 'titleAlternate'
      ? sectionEligibilityTest.titleAlternate
      : sectionEligibilityTest.title;

  return (
    < Layout
      pageContext={props.pageContext}
      location={location}
      footer={templateStyle.footer.type}
      typeHeader={templateStyle.header.type}
      dataSite={dataSite}
    >
      <ModalWrapper
        name="popup-exit"
        type="modal-exit"
        directions={['top']}
        cookie={true}
        cookieExpire={1}
        iconType={false}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        modalLink={modalLink}
        setModalLink={setModalLink}
        typeExit
      />

      <Section padSmall white shadow jsSticky="top" jsStickyResponsive="bottom">
        <Wrapper narrow>
          <ReminderForm />
        </Wrapper>
      </Section>

      <Section padSmall name={sectionEligibilityTest.contentfulid}>
        <Wrapper narrow row flexCenter>
          <div className={`m-eligibility`} style={{ margin: '40px auto 0', maxWidth: '550px' }}>
            <Column colMd={`12`}>
              {isStepbar && <Stepbar active={`1`} />}
              {stepActive !== 'success' && (
                <Title
                  tag={`h1`}
                  margin="margBot0"
                  size="regular"
                  fontStyle="centered"
                  content={title}
                />
              )}
              <CardGroup flex>
                <div ref={myRef} className={'row'}>
                  {Object.keys(jsonObject.fields).map((key) => {
                    let arrayItems = [];
                    return (
                      <Card
                        col={12}
                        key={key}
                        id={jsonObject.fields[key].name}
                        noMarginTop
                        noPaddingSides
                        className={stepActive === jsonObject.fields[key].name ? '' : 'd-none'}
                      >
                        {templateStyle.eligibility.subtitle && (
                          <Text content={jsonObject.general[local].subtitle} />
                        )}
                        {Object.keys(jsonObject.fields[key].items).forEach((keyItem) => {
                          arrayItems.push(replaceVariable(jsonObject.fields[key].items[keyItem]));
                        })}
                        {jsonObject.fields[key].helperMessage && jsonObject.fields[key].helperMessage[local] && (
                          <Dropdown
                            simple
                            trigger={jsonObject.general[local].label_help}
                            contentBody={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceVariable(
                                    jsonObject.fields[key].helperMessage[local]
                                      ? jsonObject.fields[key].helperMessage[local]
                                      : jsonObject.fields[key].helperMessage,
                                  ),
                                }}
                              />
                            }
                          />
                        )}

                        <Radio
                          onChangeFunction={() => setError(false)}
                          label={replaceVariable(
                            jsonObject.fields[key].label[local]
                              ? jsonObject.fields[key].label[local]
                              : jsonObject.fields[key].label,
                          )}
                          block
                          name={jsonObject.fields[key].name}
                          items={arrayItems}
                        />

                        {error && (
                          <Text
                            color="errorMsg"
                            content={
                              jsonObject.fields[key].errorMessage[local]
                                ? jsonObject.fields[key].errorMessage[local]
                                : jsonObject.fields[key].errorMessage
                            }
                          />
                        )}
                        <GroupButtons center smReverse>
                          {key !== '0' && (
                            <Button
                              type="alternative"
                              size="large"
                              widthWrapper="fitContent"
                              content={jsonObject.general[local].btnBack}
                              onClickFunction={() => handleClickBack()}
                            />
                          )}
                          <Button
                            type="brand"
                            size="large"
                            widthWrapper="maxWidth"
                            centered
                            content={jsonObject.general[local].btnNext}
                            onClickFunction={() => handleClickNext(jsonObject.fields[key].name)}
                          />
                        </GroupButtons>
                      </Card>
                    );
                  })}
                  <Card
                    id={jsonObject.success.name}
                    headSuccess
                    borderRadius
                    white
                    shadow
                    centered
                    bodyFlexColumn
                    noMarginTop
                    col={12}
                    headContent={
                      <Title
                        size="small"
                        color={'white'}
                        fontStyle="centered"
                        content={jsonObject.success.title[local]}
                      />
                    }
                    className={stepActive === jsonObject.success.name ? '' : 'd-none'}
                  >
                    <Column colLg={`5`} negativeMargBottomBig>
                      <Image src={Document} style={{ maxWidth: '100%' }} />
                    </Column>
                    <Column colLg={`6`} offsetLg={`1`} mdCentered>
                      <Text content={jsonObject.success.description[local]} />
                      <Button
                        type="brand"
                        size="large"
                        width="width100"
                        iconArrow
                        id={jsonObject.success.id}
                        content={jsonObject.success.button[local]}
                        link={`${urlHash}`}
                      />
                    </Column>
                  </Card>

                  <Card
                    id={jsonObject.error.name}
                    borderRadius
                    white
                    shadow
                    col={12}
                    className={stepActive === jsonObject.error.name ? '' : 'd-none'}
                  >
                    <Title size="small" content={jsonObject.error.title[local]} />
                    <Text content={jsonObject.error.description[local]} />
                    <Text
                      fontWeight="strong"
                      margin="margTop20"
                      content={jsonObject.error.question[local]}
                    />
                    <Button
                      type="error"
                      size="large"
                      iconArrow
                      content={jsonObject.error.button[local]}
                      onClickFunction={goToFirstStep}
                    />
                  </Card>
                </div>
              </CardGroup>
            </Column>
          </div>
        </Wrapper>
      </Section>

      {
        sectionBenefits && stepActive === 'success' && (
          <Section name={sectionBenefits.contentfulid} padTop0>
            <Wrapper narrow>
              <Benefits limitWidth benefitsItems={sectionBenefits.content[0].cards} sizeIcon={72} />
            </Wrapper>
          </Section>
        )
      }
    </Layout >
  );
};

export default Eligibility;
