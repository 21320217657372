import React from 'react';
import './radio.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Radio = (props) => {
  const classnames = classNames('m-input-radio--inner', {
    'm-input-radio--inner-block': props.block,
  });
  const local = process.env.CONTENTFUL_LOCAL;
  return (
    <div className={`m-input--group`}>
      <label className={`m-input-radio--label`} htmlFor={props.name}>
        {props.label}
      </label>
      {props.items.map((radioItem, key) => {
        return (
          <div
            className={props.className ? props.className + ` ` + classnames : classnames}
            key={key}
          >
            <label className={`m-input-radio-item--label`} key={radioItem.id}>
              <input
                className={`m-input-radio`}
                type="radio"
                id={`${props.name}_${radioItem.id}`}
                name={props.name}
                value={radioItem.value}
                onChange={props.onChangeFunction && (() => props.onChangeFunction())}
              />
              <span>{radioItem.label[local]?radioItem.label[local]:radioItem.label}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default Radio;
