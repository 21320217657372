import React from 'react';
import classNames from 'classnames';
import './stepbar.scss';
import { graphql, useStaticQuery } from 'gatsby';
import RichText from '../../modules/RichText/richText';

const Stepbar = (props) => {
  const classnames = classNames('m-stepbar', {});

  const { stepbar } = useStaticQuery(
    graphql`
      query {
        stepbar: contentfulCardGroup(nameGroup: {eq: "step-bar"}) {
          cards {
            headText
            bodyText {
              raw
            }
          }
        }
      }
    `,
  );

  let classActive;

  return (
    <div className={classnames}>
      {stepbar.cards.map((item, i) => {
        parseInt(props.active) === i + 1
          ? (classActive = 'm-stepbar__step-active')
          : (classActive = '');
        return (
          <div key={i} className={`m-stepbar__step ${classActive}`}>
            <div className={`m-stepbar__number-inner`}>
              <div className={`m-stepbar__number`}>{item.headText}</div>
              <div className={`m-stepbar__line`}></div>
            </div>
            <div className={`m-stepbar__title`}>
              {item.bodyText && <RichText content={item.bodyText} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepbar;
