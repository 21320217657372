import React from 'react';
import { Icon } from '../../elements/Icon/icon';
import { Card } from '../Card/card';
import { CardGroup } from '../CardGroup/cardGroup';
import { Text } from '../../elements/Text/text';
import classNames from 'classnames';
import './benefits.scss';

export const Benefits = (props) => {
  const classnames = classNames('m-benefits', {
    'm-benefits--limit-width': props.limitWidth,
  });
  return (
    <CardGroup className={classnames}>
      {props.benefitsItems.map((item, i) => {
        let iconStep = require(`../../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/icon-benefit-${i + 1}.png`).default;
        return (
          <Card bodyPad0 centered flexColumnCenter key={i} col={12} colMd={4}>
            <Icon margBottom icon={iconStep} height={props.sizeIcon ? props.sizeIcon : 100} width={props.sizeIcon ? props.sizeIcon : 100} />
            <Text content={JSON.parse(item.bodyText.raw).content[0].content[0].value} fontStyle="center" />
          </Card>
        );
      })}
    </CardGroup>
  );
};

//export default Benefits;
