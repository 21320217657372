export default function replaceVariable(text) {
  const dateNow = new Date();
  let newtext = '';
  if (JSON.parse(JSON.stringify(text).includes('{{ date_365 }}'))) {
    let date_365 = dateNow;
    date_365.setFullYear(date_365.getFullYear() - 1);
    let stringDate_365 =
      ('0' + (date_365.getMonth() + 1)).slice(-2) + '/' + ('0' + date_365.getDate()).slice(-2) + '/' + date_365.getFullYear();
    newtext = JSON.parse(JSON.stringify(text).replace(/{{ date_365 }}/g, stringDate_365));
  } else if (JSON.parse(JSON.stringify(text).includes('{{ date_150 }}'))) {
    let date_150 = dateNow;
    date_150.setDate(date_150.getDate() - 150);
    let stringDate_150 =
      ('0' + (date_150.getMonth() + 1)).slice(-2) + '/' + ('0' + date_150.getDate()).slice(-2) + '/' + date_150.getFullYear();
    newtext = JSON.parse(JSON.stringify(text).replace(/{{ date_150 }}/g, stringDate_150));
  } else if (JSON.parse(JSON.stringify(text).includes('{{ date_180 }}'))) {
    let date_180 = dateNow;
    date_180.setDate(date_180.getDate() + 180);
    let stringDate_180 =
      ('0' + (date_180.getMonth() + 1)).slice(-2) + '/' + ('0' + date_180.getDate()).slice(-2) + '/' + date_180.getFullYear();
    newtext = JSON.parse(JSON.stringify(text).replace(/{{ date_180 }}/g, stringDate_180));
  } else if (JSON.parse(JSON.stringify(text).includes('{{ img_passport'))) {
    const img_passport1N400 = require(`../../images/shared/passport1N400.png`).default;
    const img_passport2N400 = require(`../../images/shared/passport2N400.png`).default;
    newtext = JSON.parse(
      JSON.stringify(text).replace(/{{ img_passport1N400 }}/g, `<img src='${img_passport1N400}'/>`),
    );
    newtext = JSON.parse(
      JSON.stringify(newtext).replace(
        /{{ img_passport2N400 }}/g,
        `<img src='${img_passport2N400}'/>`,
      ),
    );
  } else {
    newtext = text;
  }
  return newtext;
}
