import React from 'react';
import classNames from 'classnames';
import './groupButtons.scss';

const GroupButtons = (props) => {
  const classnames = classNames(`m-group-buttons`, {
    // size
    'm-group-buttons--center': props.center,
    'm-group-buttons--sm-reverse': props.smReverse,
    
  });
  return (
    <>
      <div className={classnames}>
        {props.children}
      </div>
    </>
  );
};

export default GroupButtons;